import React from "react"
import Heading from "../Heading"
import styled from "styled-components"
import BaseColumn from "../MainColumn"
import Button from "../Button"
import { palette, ifProp, theme } from "styled-tools"

const Section = styled.section`
  box-shadow: ${theme("boxShadow.secondary")};
  background-color: ${ifProp("variant", palette("white"), "transparent")};
  border-bottom: 1px solid ${ifProp("variant", palette("primary", 2), "transparent")};
`

const MainColumn = styled(BaseColumn)`
  padding: 6.4rem 0;
`

const Buttons = styled.div`
  display: flex;
  margin: 6.4rem auto 0;
  justify-content: center;
`


function Callback({ variant }) {
  return (
    <Section variant={variant}>
      <MainColumn>
        <Heading title="O que está esperando?" description="Conheça os combos disponíveis e realize seu pedido" />
        <Buttons>
          <Button to="/comprar">Faça seu pedido</Button>
          <Button to="/nossos-combos" secondary>Conheça nossos combos</Button>
        </Buttons>
      </MainColumn>
    </Section>
  )
}

export default Callback
