import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { palette, ifProp, theme } from "styled-tools"

const ButtonContainer = styled.div`
  border: 0;
  cursor: pointer;
  margin: 0 2.4rem;
  min-width: 23rem;
  font-size: 1.6rem;
  border-radius: 3px;
  text-align: center;
  padding: 1.4rem 3.2rem;
  text-transform: lowercase;
  color: ${palette("white")};
  font-weight: ${theme("fontWeight.bold")};
  transition: transform ${theme("times.3")};
  font-family: ${theme("fontFamily.secondary")};
  background-color: ${ifProp({ type: "secondary" }, palette("primary", 1), palette("primary"))};

  &:hover {
    transform: translateY(-5px);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`

function Button({ children, onClick = () => {}, to, secondary, className, disabled }) {
  return (
    <ButtonContainer
      to={to}
      onClick={onClick}
      disabled={disabled}
      className={className}
      as={to ? Link : "button"}
      type={secondary ? "secondary" : "primary"}
    >
      {children}
    </ButtonContainer>
  )
}

export default Button
