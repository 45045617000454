import React from "react"
import Seo from "../seo"
import Layout from "../Layout"
import Banner from "../Banner"
import Heading from "../Heading"
import Callback from "../Callback"
import styled from "styled-components"
import BaseColumn from "../MainColumn"
import { useStaticQuery, graphql } from "gatsby"

const Wrapper = styled(BaseColumn)`
  padding: 6.4rem 0;
`

const LongDescription = styled.p`
  width: 80%;
  margin: 0 auto;
  text-align: center;
  line-height: 1.45em;
  margin-bottom: 2.4rem;
`

function About() {
  const { file } = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "about-banner-image.png" }) {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo title="Sobre nós" />
      <Banner file={file} />
      <Wrapper id="content">
        <Heading title="Sobre nós" description="Somos uma empresa criada visando seu bem estar" />
        <LongDescription>
          A <strong>Salim Alimentos</strong> é especialista na montagem de cestas com produtos de qualidade excepcional. Com variedade entre nossos combos, você poderá receber no conforto de sua casa desde produtos alimentícios á higiene. Buscando introduzir uma maneira de compra rápida e eficaz, constantemente melhoramos nossos produtos para atender a cada um de nossos clientes de forma exemplar.
        </LongDescription>
      </Wrapper>
      <Callback variant />
    </Layout>
  )
}

export default About
